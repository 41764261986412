import { ChangeEvent, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import {
  setLateralErrorValue,
  setLateralStateValue,
  setProjectValue,
  toggleEnterLengthValues,
  toggleLateralMaxLengthReport,
} from 'shared/slices';
import { Button, Input, Modal, Row } from 'shared/ui';
import { CatalogItem } from 'shared/models';
import { getEmitterKDMaxLength } from 'shared/lib/lateralHelpers';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { formattedInputValue, mapSlopesForCalculation } from 'shared/lib';
import { LateralLengthCalcResult } from 'shared/lib/calculation/models';
import { mainCalculate } from 'shared/lib/calculation/laterals/mainCalculateLaterals';

export const EnterLengthValues = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openEnterLengthValues } = useAppSelector((st) => st.modals);
  const { showError } = useToast();
  const { projectData } = useAppSelector((st) => st.projectData);
  const { units } = useAppSelector((st) => st.units);
  const { lateralValues, lateralErrors } = useAppSelector((st) => st.lateralState);
  const { laterals } = useAppSelector((state) => state.laterals);
  const { emitters } = useAppSelector((state) => state.emitters);

  const onChangeStart = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setLateralStateValue({ maxLengthStart: formattedInputValue(e) }));
  };
  const onChangeEnd = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setLateralStateValue({ maxLengthEnd: formattedInputValue(e) }));
  };
  const onChangeIncrement = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setLateralStateValue({ maxLengthIncrement: formattedInputValue(e) }));
  };

  const toggle = () => dispatch(toggleEnterLengthValues());

  const onMaxLengthCalculate = () => {
    const currentProduct = projectData.integrated
      ? (laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem)
      : (emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem);

    const currentLateral = laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem;
    const currentEmitter = emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem;

    const params = {
      runLengthEnd: +lateralValues.maxLengthEnd,
      runLengthIncrement: +lateralValues.maxLengthIncrement,
      runLengthStart: +lateralValues.maxLengthStart,
      HDCalc: projectData.headlossCalc,
      HWCoeff: lateralValues.isCustom ? +projectData.hwCoef : currentLateral.hwCof,
      A: lateralValues.isCustom ? +projectData.emitterA : currentProduct.emitterQa,
      B: lateralValues.isCustom ? +projectData.emitterB : currentProduct.emitterQb,
      Kd: lateralValues.isCustom
        ? +projectData.lateralKd
        : projectData.integrated
          ? currentProduct.kd
          : getEmitterKDMaxLength(currentEmitter, currentLateral),
      cvv: lateralValues.isCustom ? +projectData.cvv : Math_round(currentLateral.mManuFactCv, 3),
      dia: +projectData.lateralInletDia,
      spacing: +projectData.emitterSpacing,
      Length: +projectData.lateralLength2,
      pMin: +projectData.emitterPMin,
      pMax: +projectData.emitterPMax,
      pInlet: projectData.lateralInletPressure as number,
      inletPressure: projectData.lateralInletPressure as number,
      PRA: currentLateral.pipeDha,
      PRB: currentLateral.pipeDhb,
      PRC: currentLateral.pipeDhc,
      slopes: mapSlopesForCalculation(projectData.lateralSlopes),
      calcType: projectData?.euType ?? 'Classic',
      flushVelocity: (projectData.flushingVelocity as number) ?? 0,
      maxQDelta: lateralValues.minimumVariation as number,
      MaxLengthCalcMethod: lateralValues.uniformityType,
      minEU: lateralValues.minimumEu as number,
      MinimumEU: lateralValues.minimumEu as number,
    };

    console.log('####: values', params);

    const result = mainCalculate(params, units, true) as LateralLengthCalcResult;

    console.log('####: result', result);

    if (result.error) {
      showError(t(result.error));
      return;
    }

    dispatch(
      setLateralStateValue({
        resultMaxLengthDU: result.LateralMaxDu,
        resultMaxLengthEU: 0,
        resultMaxLengthMaxVelocity: result.LateralMaxVelocity,
        resultMaxLengthTotalHeadloss: result.TotalHeadloss,
        resultMaxLength: result.MaxLateralLength,
        resultMaxLengthTotalFlow: result.TotalFlow,
        resultMaxLengthPMax: result.Actpmax,
        resultMaxLengthPMin: result.Actpmin,
        resultMaxLengthQMinQMax: result.minByqMax,
        resultMaxLengthReportArray: result.sortedLateralMainReportResults,
      })
    );

    if (projectData.lateralInletPressure === 0 && result.SLInletPressure) {
      dispatch(setProjectValue({ lateralInletPressure: result.SLInletPressure }));
    }

    if (result.Actpmax > +projectData.emitterPMax) {
      dispatch(setLateralErrorValue({ maxLengthPMax: true }));
    } else {
      dispatch(setLateralErrorValue({ maxLengthPMax: false }));
    }

    if (result.Actpmin < +projectData.emitterPMin) {
      dispatch(setLateralErrorValue({ maxLengthPMin: true }));
    } else {
      dispatch(setLateralErrorValue({ maxLengthPMin: false }));
    }

    toggle();
    dispatch(toggleLateralMaxLengthReport());
  };

  useEffect(() => {
    if (+lateralValues.maxLengthStart <= 0) {
      dispatch(setLateralErrorValue({ maxLengthStart: true }));
    } else {
      dispatch(setLateralErrorValue({ maxLengthStart: false }));
    }
  }, [lateralValues.maxLengthStart]);

  useEffect(() => {
    if (+lateralValues.maxLengthEnd <= 0) {
      dispatch(setLateralErrorValue({ maxLengthEnd: true }));
    } else {
      dispatch(setLateralErrorValue({ maxLengthEnd: false }));
    }
  }, [lateralValues.maxLengthEnd]);

  useEffect(() => {
    if (+lateralValues.maxLengthIncrement <= 0) {
      dispatch(setLateralErrorValue({ maxLengthIncrement: true }));
    } else {
      dispatch(setLateralErrorValue({ maxLengthIncrement: false }));
    }
  }, [lateralValues.maxLengthIncrement]);

  return (
    <Modal maxWidth="md" title={`${t('enterLengthValues')}`} open={openEnterLengthValues} onClose={toggle}>
      <Grid item container xs={12} mt={1} columnSpacing={3}>
        <Grid item xs={4}>
          <Input
            label={`${t('start')}`}
            value={lateralValues.maxLengthStart}
            onChange={onChangeStart}
            errorText={`${t('Not Valid')}`}
            isErrorBox={lateralErrors.maxLengthStart}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            label={`${t('end')}`}
            value={lateralValues.maxLengthEnd}
            onChange={onChangeEnd}
            errorText={`${t('Not Valid')}`}
            isErrorBox={lateralErrors.maxLengthEnd}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            label={`${t('increment')}`}
            value={lateralValues.maxLengthIncrement}
            onChange={onChangeIncrement}
            errorText={`${t('Not Valid')}`}
            isErrorBox={lateralErrors.maxLengthIncrement}
          />
        </Grid>
      </Grid>

      <Row justifyContent="center" sx={{ mt: '20px' }}>
        <Row spacing="30px">
          <Button sx={{ width: '200px' }} onClick={onMaxLengthCalculate}>
            {t('showReport')}
          </Button>
          <Button variant="outlined" onClick={toggle} sx={{ width: '200px' }}>
            {t('cancel')}
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};
