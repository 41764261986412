import { LimitsRangeLaterals } from '../common/limitRange';
import { CalculateLaterals } from '../calculateLaterals';
import { GetLateralSlopes } from '../getLateralSlopes';
import { Math_round } from '../mathRound';
import { ConvertUnit } from '../unitConverter';
import { lateralsMain } from './lateralsMain';
import { Unit } from 'shared/models';
import { METRIC_DEFAULTS } from 'shared/constants';

// const arrayCopy = <T>(
//   sourceArray: T[],
//   sourceIndex: number,
//   destinationArray: T[],
//   destinationIndex: number,
//   length: number
// ): void => {
//   // Check for invalid input
//   if (
//     !Array.isArray(sourceArray) ||
//     !Array.isArray(destinationArray) ||
//     sourceIndex < 0 ||
//     destinationIndex < 0 ||
//     length < 0
//   ) {
//     throw new Error('Invalid input');
//   }

//   // Perform the copy
//   const sourceSlice = sourceArray.slice(sourceIndex, sourceIndex + length);
//   destinationArray.splice(destinationIndex, length, ...sourceSlice);
// };

export function mainCalculate(laterals: Partial<lateralsMain>, unitSettings: Unit, showReport: boolean) {
  const arrFlow: number[] = [];
  const arrVelocity: number[] = [];
  const arrPressure: number[] = [];
  const arrEU: number[] = [];
  const arrHeadloss: number[] = [];
  const rangeResults: LimitsRangeLaterals = {};
  const validationResult = mainLateralsValidationInput(laterals, showReport);
  // eslint-disable-next-line no-prototype-builtins
  if (validationResult.hasOwnProperty('error')) {
    return validationResult;
  }
  let result = {};
  const convertedLaterals = mainCalculateLateralsConvert(laterals, unitSettings);

  let i = 4000 * convertedLaterals.spacing!;
  let actualInletPressure = convertedLaterals.inletPressure;
  convertedLaterals.inletPressure = actualInletPressure;

  if (convertedLaterals.MaxLengthCalcMethod! != 'Minimum EU') {
    convertedLaterals.MinimumEU = 0;
  }
  if (convertedLaterals.MaxLengthCalcMethod! != 'Q(delta)') {
    convertedLaterals.MaxQDelata = 0;
  }

  switch (convertedLaterals.HDCalc) {
    case 'H.W':
      convertedLaterals.isHW = 0;
      break;
    case 'D.W':
      convertedLaterals.isHW = 1;
      if (convertedLaterals.PRA === 0 || convertedLaterals.PRB === 0 || convertedLaterals.PRC === 0) {
        convertedLaterals.isHW = 2;
      }
      break;
    case 'D.W-RE':
      convertedLaterals.isHW = 2;
      break;
  }

  let slopes = GetLateralSlopes(convertedLaterals.slopes);
  convertedLaterals.slopes = slopes;
  convertedLaterals.Length = i;
  const limit = CalculateLateralsV1(
    convertedLaterals,
    unitSettings,
    true,
    arrVelocity,
    arrPressure,
    arrEU,
    arrHeadloss,
    arrFlow,
  );
  if (+limit! <= 0) {
    return { error: 'msgRetry' };
  }
  convertedLaterals.Length = limit as number;
  CalculateLateralsV1(convertedLaterals, unitSettings, false, arrVelocity, arrPressure, arrEU, arrHeadloss, arrFlow);
  let startRunLength = 1;
  let endRunLength = 5000 * convertedLaterals.spacing!;
  let increment = 1;

  if (showReport) {
    startRunLength = convertedLaterals.runLengthStart!;
    endRunLength = convertedLaterals.runLengthEnd!;
    increment = convertedLaterals.runLengthIncrement!;
  }
  let count = 0;
  count = arrFlow.length;
  let totalflowfordu = 0;
  // let jj = 0;
  let lstFlow = [];

  for (let jj = 0; jj <= count - 1; jj++) {
    lstFlow.push(arrFlow[jj]);
    totalflowfordu += arrFlow[jj] ? arrFlow[jj] : 0;
  }
  let QFlush =
    convertedLaterals.flushVelocity! * ((Math.PI * convertedLaterals.dia! * convertedLaterals.dia! * Math.pow(10, -6)) / 4) * 3600 * 1000;
  totalflowfordu += QFlush;
  lstFlow.sort((a, b) => a - b);
  let aveFlow = totalflowfordu / count;
  //Quarter
  let totalQuarterFlow = 0.0;

  for (let jj = 0; jj <= Math.floor(count / 4 - 1); jj++) {
    totalQuarterFlow += lstFlow[jj];
  }

  let Du = 0;
  if (count < 4) {
    Du = 100;
  }
  let minFlow = 0;
  // if (count >= 4) {
  //   minFlow = totalQuarterFlow / parseInt((count / 4).toString());
  // } else {
  //   minFlow = totalQuarterFlow / count / 4;
  // }
  minFlow = totalQuarterFlow / Math.floor(count / 4);
  Du = Math_round(minFlow / aveFlow, 3);

  let LateralMaxVelocity = maxVelocity(unitSettings, arrVelocity);
  if (LateralMaxVelocity > 3.5) {
    rangeResults.LateralMaxVelocity = { range: 'over' };
    // txtLateralMaxVelocity.Background = new SolidColorBrush(Colors.Red);
    // txtLateralMaxVelocity.Foreground = new SolidColorBrush(Colors.White);
  } else {
    rangeResults.LateralMaxVelocity = { range: 'in' };
    // txtLateralMaxVelocity.Foreground = txtLastLateralPressure.Foreground;
    // txtLateralMaxVelocity.Background = txtLastLateralPressure.Background;
  }

  let maxPressure = PressureResult(unitSettings, arrPressure).maxPressure;
  let minPressure = PressureResult(unitSettings, arrPressure).minPressure;

  if (maxPressure > convertedLaterals.pMax! || maxPressure < convertedLaterals.pMin!) {
    rangeResults.ActpmaxRange = { range: 'over' };
    // TxtActpmax.Background = new SolidColorBrush(Colors.Red);
    // TxtActpmax.Foreground = new SolidColorBrush(Colors.White);
  } else {
    rangeResults.ActpmaxRange = { range: 'in' };
  }

  if ((minPressure * 1000 + 1) / 1000 < convertedLaterals.pMin! || minPressure > convertedLaterals.pMax!) {
    rangeResults.ActpminRange = { range: 'over' };

    // TxtActpmin.Background = new SolidColorBrush(Colors.Red);
    // TxtActpmin.Foreground = new SolidColorBrush(Colors.White);
  } else {
    rangeResults.ActpminRange = { range: 'in' };

    // TxtActpmin.Foreground = new SolidColorBrush(Colors.Green);
    // TxtActpmin.Foreground = txtLastLateralPressure.Foreground;
  }

  result = { ...result, LateralMaxDu: Math_round(Du, 3) };
  result = { ...result, LateralMaxVelocity: LateralMaxVelocity };

  if (convertedLaterals.MaxLengthCalcMethod === 'Minimum EU') {
    result = {
      ...result,
      MaxLateralLength: maxLateralLengthEU(convertedLaterals, unitSettings, arrEU),
    };
  } else if (convertedLaterals.MaxLengthCalcMethod === 'Q(delta)') {
    result = {
      ...result,
      MaxLateralLength: maxLateralLengthQ(convertedLaterals, arrFlow, unitSettings),
    };
  } else if (convertedLaterals.MaxLengthCalcMethod === 'Pressure') {
    result = {
      ...result,
      MaxLateralLength: maxLateralLengthPressure(convertedLaterals, arrEU, unitSettings),
    };
  }
  result = { ...result, TotalFlow: totalFlow(unitSettings, arrFlow, QFlush, arrEU) };

  if (actualInletPressure == 0) {
    result = {
      ...result,
      SLInletPressure: sLInletPressure(unitSettings, arrEU, arrPressure),
    };
  }
  result = {
    ...result,
    TotalHeadloss: totalHeadloss(unitSettings, arrHeadloss),
  };

  result = {
    ...result,
    Actpmin: minPressure,
  };
  result = {
    ...result,
    Actpmax: maxPressure,
  };
  if (showReport) {
    let lateralMainReportResults = [];
    let index = Math.round(startRunLength / convertedLaterals.spacing!);
    let j = startRunLength;

    // console.log('result index', index);
    // console.log('startRunLength', startRunLength);
    // console.log('result report', result);

    while (j <= endRunLength && j < arrFlow.length * convertedLaterals.spacing!) {
      let lateralMainReportResult = {};
      let len = 0;
      len = Math.min(endRunLength, arrFlow.length * convertedLaterals.spacing!) - j + startRunLength;
      lateralMainReportResult = {
        ...lateralMainReportResult,
        length: ConvertUnit(len, METRIC_DEFAULTS.Length, unitSettings.length, null),
      };
      let arrQ = [];
      arrQ = [...arrFlow.slice(0, index)];
      let lstQ = [...arrQ];
      lstQ.sort((a, b) => a - b);

      let arrTempHL: number[] = [];
      // arrayCopy(arrHeadloss, 0, arrTempHL, 0, index);
      // Array.Copy(arrHeadloss, 0, arrTempHL, 0, index);
      arrTempHL = [...arrHeadloss.slice(0, index)];

      lateralMainReportResult = { ...lateralMainReportResult, length: Math_round(len, 3) };
      lateralMainReportResult = {
        ...lateralMainReportResult,
        totalHeadloss: totalHeadlossReport(unitSettings, arrTempHL),
      };
      lateralMainReportResult = {
        ...lateralMainReportResult,
        velocity: velocityReport(unitSettings, arrVelocity, index),
      };
      lateralMainReportResult = {
        ...lateralMainReportResult,
        headloss: headlossReport(unitSettings, arrHeadloss, index),
      };
      lateralMainReportResult = {
        ...lateralMainReportResult,
        inletPressure: inletPressurePressureReport(unitSettings, arrPressure, index),
      };
      lateralMainReportResult = {
        ...lateralMainReportResult,
        averageFlow: averageFlow(unitSettings, arrQ, index),
      };
      lateralMainReportResult = {
        ...lateralMainReportResult,
        lateralFlow: lateralFlow(unitSettings, arrQ, QFlush),
      };

      j += increment;
      lateralMainReportResults.push(lateralMainReportResult);
      index += Math.round(increment / convertedLaterals.spacing!);
    }
    let sortedLateralMainReportResults = lateralMainReportResults.reverse();
    result = { ...result, sortedLateralMainReportResults };
    result = { ...result, minByqMax: qMinByqMax(unitSettings, arrFlow) };
    result = { ...result, rangeResults: rangeResults };
  }
  return result;
}

export function mainLateralsValidationInput(laterals: Partial<lateralsMain>, showReport: boolean) {
  if (laterals.InternalDiameter! <= 0) {
    return { data: laterals, error: 'msgInvalidDia' };
  }
  if (laterals.A! <= 0) {
    laterals.A = 0;
    return { data: laterals, error: 'msgInvalidA' };
  }
  // if (laterals.B! <= 0) {
  //   laterals.B = 0;
  //   return { data: laterals, error: 'msgInvalidB' };
  // }

  // if (laterals.inletPressure! <= 0) {
  //   laterals.inletPressure = 0;
  //   return { data: laterals, error: 'msgInvalidInletPr' };
  // }

  if (showReport && laterals.runLengthStart! <= 0) {
    return { data: laterals, error: 'msgInvalidRunLenSt' };
  }

  if (showReport && laterals.runLengthEnd! < laterals.runLengthStart!) {
    return { data: laterals, error: 'msgInvalidRunLenEnd' };
  }

  if (showReport && laterals.runLengthIncrement! <= 0) {
    return { data: laterals, error: 'msgInvalidRunLenIncr' };
  }

  if (laterals.spacing! <= 0) {
    return { data: laterals, error: 'msgInvalidSpacing' };
  }
  if (laterals.pMin! <= 0) {
    return { data: laterals, error: 'msgInvalidPmin' };
  }
  if (!laterals.pMax) {
    return { data: laterals, error: 'msgInvalidPmax' };
  }
  if (laterals.pMax < laterals.pMin!) {
    return { data: laterals, error: 'msgPmaxless' };
  }
  if (laterals.inletPressure! > laterals.pMax) {
    return { data: laterals, error: 'msgInvalidInletPr' };
  }
  if (laterals.MinimumEU === 100 || laterals.MinimumEU! > 100 - laterals.cvv! * 100) {
    return { data: laterals, error: 'msgInvalidEu' };
  }
  if (laterals.MaxLengthCalcMethod !== 'Pressure' && laterals.B === 0) {
    return { data: laterals, error: 'msgInvalidCalc' };
  }

  if (laterals.EUType === '') {
    return { data: laterals, error: 'Please select EU Calculation Type' };
  }
  return laterals;
}

export function mainCalculateLateralsConvert(laterals: Partial<lateralsMain>, unitSettings: Unit) {
  return {
    ...laterals,
    InternalDiameter: ConvertUnit(
      laterals.dia!,
      unitSettings.pipeDiameter,
      METRIC_DEFAULTS.PipeDiameter,
      'PipeDiameter',
    ),
    inletPressure: ConvertUnit(
      laterals.inletPressure!,
      unitSettings.pressure,
      METRIC_DEFAULTS.Pressure,
      null,
    ),
    runLengthStart: ConvertUnit(
      laterals.runLengthStart!,
      unitSettings.length,
      METRIC_DEFAULTS.Length,
      null,
    ),
    runLengthEnd: ConvertUnit(
      laterals.runLengthEnd!,
      unitSettings.length,
      METRIC_DEFAULTS.Length,
      null,
    ),
    runLengthIncrement: ConvertUnit(
      laterals.runLengthIncrement!,
      unitSettings.length,
      METRIC_DEFAULTS.Length,
      null,
    ),
    dia: ConvertUnit(
      laterals.dia!,
      unitSettings.pipeDiameter,
      METRIC_DEFAULTS.PipeDiameter,
      'PipeDiameter',
    ),
    spacing: ConvertUnit(
      laterals.spacing!,
      unitSettings.lateralSpacing,
      METRIC_DEFAULTS.LateralSpacing,
      null,
    ),
    pMin: ConvertUnit(
      laterals.pMin!,
      unitSettings.pressure,
      METRIC_DEFAULTS.Pressure,
      null,
    ),
    pMax: ConvertUnit(
      laterals.pMax!,
      unitSettings.pressure,
      METRIC_DEFAULTS.Pressure,
      null,
    ),
    flushVelocity: ConvertUnit(
      laterals.flushVelocity!,
      unitSettings.velocity,
      METRIC_DEFAULTS.Velocity,
      null,
    ),
  };
}

export function maxVelocity(unitSettings: Unit, arrVelocity: number[]) {
  if (arrVelocity.length == 2) {
    arrVelocity[2] = 0;
  }
  arrVelocity.sort((a, b) => a - b);
  let maxVelocity = arrVelocity[arrVelocity.length - 1];

  let maxVelocityRes = ConvertUnit(maxVelocity, METRIC_DEFAULTS.Velocity, unitSettings.velocity, null);
  return Math_round(maxVelocityRes, 3);
}

export function maxLateralLengthEU(laterals: Partial<lateralsMain>, unitSettings: Unit, arrEU: number[]) {
  let maxIndex = arrEU.length;
  let maxLen = maxIndex * laterals.spacing!;
  maxLen = ConvertUnit(maxLen, METRIC_DEFAULTS.Length, unitSettings.length, null);
  return Math_round(maxLen, 3);
}

export function maxLateralLengthQ(laterals: Partial<lateralsMain>, arrFlow: number[], unitSettings: Unit) {
  let maxIndex = arrFlow.length;
  let maxLen = maxIndex * laterals.spacing!;
  maxLen = ConvertUnit(maxLen, METRIC_DEFAULTS.Length, unitSettings.length, null);
  return Math_round(maxLen, 3);
}

export function maxLateralLengthPressure(laterals: Partial<lateralsMain>, arrEU: number[], unitSettings: Unit) {
  let maxIndex = arrEU.length;
  let maxLen = maxIndex * laterals.spacing!;
  maxLen = ConvertUnit(maxLen, METRIC_DEFAULTS.Length, unitSettings.length, null);
  return Math_round(maxLen, 3);
}

export function totalFlow(unitSettings: Unit, arrFlow: number[], QFlush: number, arrEU: number[]) {
  let maxIndex = arrEU.length;
  let totalCalculatedFlow = 0;
  for (let k = 0; k <= maxIndex - 1; k++) {
    totalCalculatedFlow += arrFlow[k];
  }
  totalCalculatedFlow += QFlush;
  totalCalculatedFlow /= 1000;
  totalCalculatedFlow = ConvertUnit(totalCalculatedFlow, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null);
  return Math_round(totalCalculatedFlow, 3);
}

export function sLInletPressure(unitSettings: Unit, arrEU: number[], arrPressure: number[]) {
  let maxIndex = arrEU.length;
  let inletPressure = arrPressure[maxIndex - 1];

  let inletPressureRes = ConvertUnit(inletPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(inletPressureRes, 3);
}

export function totalHeadloss(unitSettings: Unit, arrHeadloss: number[]) {
  let totalHeadloss = 0;
  for (let ii = 0; ii <= arrHeadloss.length - 2; ii++) {
    totalHeadloss += arrHeadloss[ii];
  }

  let totalHeadlossRes = ConvertUnit(totalHeadloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(totalHeadlossRes, 3);
}

export function minByqMax(unitSettings: Unit, arrHeadloss: number[]) {
  let totalHeadloss = 0;
  for (let ii = 0; ii <= arrHeadloss.length - 2; ii++) {
    totalHeadloss += arrHeadloss[ii];
  }

  let totalHeadlossRes = ConvertUnit(totalHeadloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(totalHeadlossRes, 3);
}

export function CalculateLateralsV1(
  laterals: Partial<lateralsMain>,
  unitSettings: Unit,
  bCheckLimit: boolean,
  arrVelocity: number[],
  arrPressure: number[],
  arrEU: number[],
  arrHeadloss: number[],
  arrFlow: number[],
) {
  return CalculateLaterals(
    laterals.isHW!,
    laterals.HWCoeff!,
    laterals.dia!,
    laterals.A!,
    laterals.B!,
    laterals.Kd!,
    laterals.Length!,
    laterals.spacing!,
    laterals.slopes!,
    laterals.inletPressure!,
    laterals.pMin!,
    laterals.pMax!,
    laterals.cvv!,
    laterals.calcType!,
    laterals.flushVelocity!,
    arrFlow,
    arrVelocity,
    arrPressure,
    arrEU,
    arrHeadloss,
    laterals.PRA!,
    laterals.PRB!,
    laterals.PRC!,
    laterals.minEU!,
    laterals.maxQDelta!,
    bCheckLimit,
    unitSettings,
    laterals.MaxLengthCalcMethod!,
  );
}

export function PressureResult(unitSettings: Unit, arrPressure: number[]) {
  let count = arrPressure.length;
  let minPressure = Number.MAX_VALUE;
  let maxPressure = Number.MIN_VALUE;
  for (let i = 0; i <= count - 1; i++) {
    if (minPressure > arrPressure[i]) {
      minPressure = arrPressure[i];
    }
    if (maxPressure < arrPressure[i]) {
      maxPressure = arrPressure[i];
    }
  }

  let maxPressureRes = ConvertUnit(maxPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);

  let minPressureRes = ConvertUnit(minPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return {
    maxPressure: Math_round(maxPressureRes, 3),
    minPressure: Math_round(minPressureRes, 3),
  };
}

export function totalHeadlossReport(unitSettings: Unit, arrHeadloss: number[]) {
  let totalHeadloss = 0;
  for (let i = 0; i <= arrHeadloss.length - 1; i++) {
    totalHeadloss += arrHeadloss[i];
  }

  let totalHeadlossRes = ConvertUnit(totalHeadloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(totalHeadlossRes, 3);
}

export function velocityReport(unitSettings: Unit, arrVelocity: number[], index: number) {
  let velocity = arrVelocity[index - 1];

  let velocityRes = ConvertUnit(velocity, METRIC_DEFAULTS.Velocity, unitSettings.velocity, null);
  return Math_round(velocityRes, 3);
}

export function headlossReport(unitSettings: Unit, arrHeadloss: number[], index: number) {
  let headloss = arrHeadloss[index - 1];

  let headlossRes = ConvertUnit(headloss, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(headlossRes, 3);
}

export function qMinByqMax(unitSettings: Unit, arrFlow: number[]) {
  let qMinByqMax = arrFlow[0] / arrFlow[arrFlow.length - 1];

  let qMinByqMaxRes = ConvertUnit(qMinByqMax, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return qMinByqMaxRes;
}

export function inletPressurePressureReport(unitSettings: Unit, arrPressure: number[], index: number) {
  let inletPressure = arrPressure[index - 1];

  let inletPressureRes = ConvertUnit(inletPressure, METRIC_DEFAULTS.Pressure, unitSettings.pressure, null);
  return Math_round(inletPressureRes, 3);
}

export function averageFlow(unitSettings: Unit, arrQ: number[], index: number) {
  let averageFlow = arrQ[index - 1];

  let lateralFlowRes = ConvertUnit(averageFlow, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null);
  return Math_round(lateralFlowRes, 3);
}

export function lateralFlow(unitSettings: Unit, arrQ: number[], QFlush: number) {
  let totalFlow = 0;

  for (let index = 0; index < arrQ.length - 1; index++) {
    totalFlow += arrQ[index];
  }
  totalFlow += QFlush;

  let lateralFlowRes = ConvertUnit(totalFlow / 1000, METRIC_DEFAULTS.TotalFlow, unitSettings.totalFlow, null);

  return Math_round(lateralFlowRes, 3);
}
