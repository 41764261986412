import {
  getSubmainGroupsThunk,
  getSubmainsThunk,
  setProjectValue,
  setSubmainErrorValue,
  setSubmains,
  setSubmainStateValue,
} from 'shared/slices';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { SelectChangeEvent } from '@mui/material';
import { CatalogItem, CatalogsResponse } from 'shared/models';
import { useEffect, useMemo } from 'react';
import { METRIC_DEFAULTS } from 'shared/constants';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { FilterManifoldCatalogs } from 'shared/lib/calculation/ManifoldDesign/FilterManifoldCatalogs';
import { updateManifoldVelocities } from 'shared/lib/calculation/ManifoldDesign/UpdateManifoldVelocities';
import { calculateFlow } from 'shared/lib';

const ALL = 'All';

export const useSubmainFilters = () => {
  const dispatch = useAppDispatch();
  const { masterGroups } = useAppSelector((st) => st.masterGroups);
  const { projectData } = useAppSelector((st) => st.projectData);
  const {
    submainValues: { submainClass },
  } = useAppSelector((st) => st.submainState);
  const { units } = useAppSelector((st) => st.units);
  const { submains, submainGroups, submainsLoading, submainGroupsLoading } = useAppSelector((st) => st.submains);

  const updateSubmains = async (group: string) => {
    let submains: CatalogsResponse | undefined;

    try {
      submains = await dispatch(
        getSubmainsThunk({
          group,
          region: projectData.region,
        }),
      ).unwrap();
    } catch (e) {
      dispatch(setSubmains([]));
      console.error(e);
    }

    if (!submains) return;

    dispatch(
      setProjectValue({
        submainCatalog: submains?.data[0]?.catlog,
        manifoldPipe1: submains?.data[0]?.catlog,
        manifoldPipe2: submains?.data[0]?.catlog,
        manifoldPipe3: submains?.data[0]?.catlog,
        manifoldPipe1Dia: Math_round(
          ConvertUnit(submains?.data[0]?.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3,
        ),
        manifoldPipe2Dia: Math_round(
          ConvertUnit(submains?.data[0]?.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3,
        ),
        manifoldPipe3Dia: Math_round(
          ConvertUnit(submains?.data[0]?.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3,
        ),
      }),
    );
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ submainMasterGroup: e.target.value as string }));
    resetFilters();

    const groups = await dispatch(getSubmainGroupsThunk({
      masterGroup: e.target.value as string,
      region: projectData.region,
    })).unwrap();

    if (!groups) return;

    dispatch(setProjectValue({ submainGroup: groups.data.rootResults[0].groups }));

    await updateSubmains(groups.data.rootResults[0].groups);
  };

  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ submainGroup: e.target.value as string }));
    resetFilters();

    await updateSubmains(e.target.value as string);
  };

  const onSubmainChange = (_: any, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        submainCatalog: item.catlog,
        manifoldPipe1: item.catlog,
        manifoldPipe2: item.catlog,
        manifoldPipe3: item.catlog,
      }),
    );
  };
  const onSubmain1Change = (_: any, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        manifoldPipe1: item.catlog,
      }),
    );
  };
  const onSubmain2Change = (_: any, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        manifoldPipe2: item.catlog,
      }),
    );
  };
  const onSubmain3Change = (_: any, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        manifoldPipe3: item.catlog,
      }),
    );
  };

  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setSubmainStateValue({ submainClass: e.target.value as string }));

    const submainsFiltered = submains
      .filter((item) => (e.target.value !== ALL ? item.class?.toString().trim() === e.target.value : true))
      .sort((a, b) => a.inlet - b.inlet);

    if (e.target.value !== ALL) {
      if (projectData.manifoldMaximumVelocity && projectData.totalFlow) {
        const currentPipes = FilterManifoldCatalogs({
          maxVelocity: +projectData.manifoldMaximumVelocity,
          manifoldClass: e.target.value as string,
          totalFlow: +projectData.totalFlow,
          cbManifoldPipe: submainsFiltered,
          units,
        });

        if (!currentPipes) return;

        dispatch(
          setProjectValue({
            manifoldPipe1: currentPipes.manifoldPipe1Selected,
            manifoldPipe2: currentPipes.manifoldPipe2Selected,
            manifoldPipe3: currentPipes.manifoldPipe3Selected,
          }),
        );
      } else {
        dispatch(
          setProjectValue({
            manifoldPipe1: submainsFiltered[0].catlog,
            manifoldPipe2: submainsFiltered[0].catlog,
            manifoldPipe3: submainsFiltered[0].catlog,
          }),
        );
      }
    }
  };

  useEffect(() => {
    const submain1Dia = submainsFiltered.find((item) => item.catlog === projectData.manifoldPipe1)?.intrnl ?? 0;
    const submain2Dia = submainsFiltered.find((item) => item.catlog === projectData.manifoldPipe2)?.intrnl ?? 0;
    const submain3Dia = submainsFiltered.find((item) => item.catlog === projectData.manifoldPipe3)?.intrnl ?? 0;

    dispatch(
      setProjectValue({
        manifoldPipe1Dia: Math_round(
          ConvertUnit(submain1Dia, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3,
        ),
        manifoldPipe2Dia: Math_round(
          ConvertUnit(submain2Dia, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3,
        ),
        manifoldPipe3Dia: Math_round(
          ConvertUnit(submain3Dia, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3,
        ),
      }),
    );
  }, [projectData.manifoldPipe1, projectData.manifoldPipe2, projectData.manifoldPipe3]);

  useEffect(() => {
    const result = updateManifoldVelocities(
      {
        ManifoldFlow: +projectData.totalFlow,
        flow1: +projectData.flow1,
        flow2: +projectData.flow2,
        InternalDiameter1: +projectData.manifoldPipe1Dia,
        InternalDiameter2: +projectData.manifoldPipe2Dia,
        InternalDiameter3: +projectData.manifoldPipe3Dia,
        maxVelocity: +projectData.manifoldMaximumVelocity,
        manifoldSpacing: +projectData.manifoldSpacing,
        Pipe1Length: +projectData.manifoldPipe1Length,
        Pipe2Length: +projectData.manifoldPipe2Length,
        Pipe3Length: +projectData.manifoldPipe3Length,
      },
      projectData.isBothSides,
      units,
    );

    dispatch(
      setSubmainStateValue({
        velocity1: result.velocity1?.value,
        velocity2: result.velocity2?.value,
        velocity3: result.velocity3?.value,
      }),
    );

    if (result.velocity1 && result.velocity1.value > +projectData.manifoldMaximumVelocity) {
      dispatch(setSubmainErrorValue({ velocity1: true }));
    } else {
      dispatch(setSubmainErrorValue({ velocity1: false }));
    }
    if (result.velocity2 && result.velocity2.value > +projectData.manifoldMaximumVelocity) {
      dispatch(setSubmainErrorValue({ velocity2: true }));
    } else {
      dispatch(setSubmainErrorValue({ velocity2: false }));
    }
    if (result.velocity3 && result.velocity3.value > +projectData.manifoldMaximumVelocity) {
      dispatch(setSubmainErrorValue({ velocity3: true }));
    } else {
      dispatch(setSubmainErrorValue({ velocity3: false }));
    }
  }, [
    projectData.manifoldPipe1Dia,
    projectData.manifoldPipe2Dia,
    projectData.manifoldPipe3Dia,
    projectData.totalFlow,
    projectData.manifoldMaximumVelocity,
  ]);

  useEffect(() => {
    const flow = calculateFlow({
      flow1: +projectData.flow1,
      flow2: +projectData.flow2,
      isBothSides: projectData.isBothSides,
      totalRows: +projectData.totalRows,
    });

    dispatch(setProjectValue({ totalFlow: flow }));
  }, [
    projectData.flow1,
    projectData.flow2,
    projectData.isBothSides,
    projectData.totalRows,
    projectData.totalManifoldLength,
  ]);

  const resetFilters = () => {
    dispatch(setSubmainStateValue({ submainClass: ALL }));
  };

  const classTypeFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.altClass !== null) {
      return submainClass !== ALL ? item.altClass?.toString().trim() === submainClass : true;
    }

    return submainClass !== ALL ? item.class?.toString().trim() === submainClass : true;
  };

  const submainsFiltered = useMemo(() => {
    return submains.filter((item) => classTypeFilter(item)).sort((a, b) => a.inlet - b.inlet);
  }, [submainClass, submains]);

  useEffect(() => {
    if (submainsFiltered.length && !submainsFiltered.find((item) => item.catlog === projectData.submainCatalog)) {
      dispatch(setProjectValue({ submainCatalog: submainsFiltered[0]?.catlog }));
    }
  }, [submainsFiltered]);

  const classTypes = useMemo(() => {
    return [
      ALL,
      ...new Set(
        submains
          .map((item) =>
            projectData.region === 'USA' && item.altClass !== null
              ? item.altClass?.toString().trim()
              : item.class?.toString().trim(),
          )
          .sort((a, b) => Number(a) - Number(b)),
      ),
    ];
  }, [submains]);

  useEffect(() => {
    if (!submainsFiltered.find((item) => item.catlog === projectData.submainCatalog)) {
      dispatch(setSubmainStateValue({ submainClass: ALL }));
    }
  }, [classTypes]);

  const classTypeValue = useMemo(
    () => classTypes.find((item) => item === submainClass) ?? '',
    [submainClass, classTypes],
  );

  const submainProduct = useMemo(
    () => submainsFiltered.find((item) => item.catlog === projectData.lateralCatalog) ?? submainsFiltered[0],
    [projectData.lateralCatalog, submainsFiltered],
  );

  const submainSubtype = useMemo(
    () =>
      submainGroups.find((item) => item.groups === projectData.submainGroup.trim())
        ? projectData.submainGroup.trim()
        : '',
    [projectData.submainGroup, submainGroups],
  );

  const filteredMasterGroups = useMemo(() => masterGroups.filter((item) => item.submain), [masterGroups]);

  const submainPipe1 = useMemo(
    () => submainsFiltered.find((item) => item.catlog === projectData.manifoldPipe1) ?? submainsFiltered[0],
    [projectData.manifoldPipe1, submainsFiltered],
  );
  const submainPipe2 = useMemo(
    () => submainsFiltered.find((item) => item.catlog === projectData.manifoldPipe2) ?? submainsFiltered[0],
    [projectData.manifoldPipe2, submainsFiltered],
  );
  const submainPipe3 = useMemo(
    () => submainsFiltered.find((item) => item.catlog === projectData.manifoldPipe3) ?? submainsFiltered[0],
    [projectData.manifoldPipe3, submainsFiltered],
  );

  return {
    submainProduct,
    submainsLoading,
    submainGroupsLoading,
    submainPipe1,
    submainPipe2,
    submainPipe3,
    masterGroups: filteredMasterGroups,
    masterGroupId: projectData.submainMasterGroup,
    onMasterGroupChange,
    submainSubtype,
    onGroupTypeChange,
    classType: classTypeValue,
    onClassTypeChange,
    classTypes,
    submainsFiltered,
    onSubmain1Change,
    onSubmain2Change,
    onSubmain3Change,
    onSubmainChange,
  };
};
