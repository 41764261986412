import { useEffect, useMemo } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { CatalogItem, CatalogsResponse } from 'shared/models';
import { SelectChangeEvent } from '@mui/material';
import {
  getLateralGroupsThunk,
  getLateralsThunk,
  setLaterals,
  setLateralStateValue,
  setProjectValue,
} from 'shared/slices';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { METRIC_DEFAULTS } from 'shared/constants';

const ALL = 'All';

export const useLateralFilters = () => {
  const dispatch = useAppDispatch();
  const { projectData } = useAppSelector((st) => st.projectData);
  const { laterals, lateralGroups } = useAppSelector((st) => st.laterals);
  const {
    lateralValues: {
      lateralClass,
      lateralDiameter,
      lateralFlowPer,
      lateralNominalFlow,
      lateralSpacing,
      integratedCatalog,
      integratedGroup,
      integratedMasterGroup,
      onlineCatalog,
      onlineGroup,
      onlineMasterGroup,
    },
  } = useAppSelector((state) => state.lateralState);
  const { masterGroups } = useAppSelector((state) => state.masterGroups);
  const { units } = useAppSelector((state) => state.units);

  const updateLaterals = async (group: string) => {
    if (projectData.integrated) {
      dispatch(setLateralStateValue({ integratedGroup: group }));
    }
    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineGroup: group }));
    }

    let laterals: CatalogsResponse | undefined;

    try {
      laterals = await dispatch(
        getLateralsThunk({
          group,
          region: projectData.region,
        }),
      ).unwrap();
    } catch (e) {
      dispatch(setLaterals([]));
      console.error(e);
    }

    if (!laterals) return;

    if (
      projectData.integrated &&
      integratedCatalog &&
      laterals.data.find((item) => item.catlog === integratedCatalog)
    ) {
      const product = laterals.data.find((item) => item.catlog === integratedCatalog) as CatalogItem;
      dispatch(setLateralStateValue({ integratedCatalog: integratedCatalog }));
      dispatch(
        setProjectValue({
          lateralCatalog: product.catlog,
          ...(projectData.integrated && {
            lateralInletDia: Math_round(
              ConvertUnit(product.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
              3,
            ),
            emitterSpacing: Math_round(
              ConvertUnit(product.spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
              3,
            ),
            zoneEmitterSpacing: Math_round(
              ConvertUnit(product.spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
              3,
            ),
            zoneEmitterFlowRate: Math_round(ConvertUnit(product.qnom ?? 0, METRIC_DEFAULTS.Flow, units.flow, null), 3),
            emitterPMax: Math_round(ConvertUnit(product.pMax, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
            emitterPMin: Math_round(ConvertUnit(product.pMin, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
            emitterA: laterals.data[0]?.emitterQa,
            emitterB: laterals.data[0]?.emitterQb,
            lateralKd: laterals.data[0]?.kd,
            cvv: laterals.data[0]?.mManuFactCv,
            hwCoef: laterals.data[0]?.hwCof,
            emitterNominalPressure: laterals.data[0]?.qnom,
            emitterNominalFlow: laterals.data[0]?.PressureNominal_m_,
          }),
        }),
      );
      return;
    }

    if (!projectData.integrated && onlineCatalog && laterals.data.find((item) => item.catlog === onlineCatalog)) {
      const product = laterals.data.find((item) => item.catlog === onlineCatalog) as CatalogItem;
      dispatch(setLateralStateValue({ onlineCatalog: onlineCatalog }));
      dispatch(
        setProjectValue({
          lateralCatalog: product.catlog,
          ...(!projectData.integrated && {
            lateralInletDia: Math_round(
              ConvertUnit(product.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
              3,
            ),
          }),
        }),
      );
      return;
    }

    if (projectData.integrated) {
      dispatch(
        setLateralStateValue({
          integratedCatalog: laterals.data[0]?.catlog,
        }),
      );
    }

    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineCatalog: laterals.data[0]?.catlog }));
    }

    dispatch(
      setProjectValue({
        lateralCatalog: laterals.data[0]?.catlog,
        ...(projectData.integrated && {
          lateralInletDia: Math_round(
            ConvertUnit(laterals.data[0]?.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
            3,
          ),
          emitterSpacing: Math_round(
            ConvertUnit(laterals.data[0]?.spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
            3,
          ),
          zoneEmitterSpacing: Math_round(
            ConvertUnit(laterals.data[0]?.spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
            3,
          ),
          zoneEmitterFlowRate: Math_round(
            ConvertUnit(laterals.data[0]?.qnom ?? 0, METRIC_DEFAULTS.Flow, units.flow, null),
            3,
          ),
          emitterPMax: Math_round(
            ConvertUnit(laterals.data[0]?.pMax, METRIC_DEFAULTS.Pressure, units.pressure, null),
            3,
          ),
          emitterPMin: Math_round(
            ConvertUnit(laterals.data[0]?.pMin, METRIC_DEFAULTS.Pressure, units.pressure, null),
            3,
          ),
          emitterA: laterals.data[0]?.emitterQa,
          emitterB: laterals.data[0]?.emitterQb,
          lateralKd: laterals.data[0]?.kd,
          cvv: laterals.data[0]?.mManuFactCv,
          hwCoef: laterals.data[0]?.hwCof,
          emitterNominalPressure: laterals.data[0]?.qnom,
          emitterNominalFlow: laterals.data[0]?.PressureNominal_m_,
        }),
        ...(!projectData.integrated && {
          lateralInletDia: Math_round(
            ConvertUnit(laterals.data[0]?.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
            3,
          ),
        }),
      }),
    );
  };
  const onMasterGroupChange = async (value: string) => {
    const currentMasterGroupChosen = masterGroups.find((mg) => mg.id === value);
    const isDripTapeTypeChosen = currentMasterGroupChosen?.mastergroupname.includes('Drip Tape');

    dispatch(setProjectValue({ lateralMasterGroup: value, headlossCalc: isDripTapeTypeChosen ? 'D.W-RE' : 'D.W' }));

    if (projectData.integrated) {
      dispatch(setLateralStateValue({ integratedMasterGroup: value }));
    }

    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineMasterGroup: value }));
    }

    resetFilters();

    const groups = await dispatch(getLateralGroupsThunk({ masterGroup: value, region: projectData.region })).unwrap();

    if (!groups) return;

    if (
      projectData.integrated &&
      integratedGroup &&
      groups.data.rootResults.find((item) => item.groups === integratedGroup)
    ) {
      dispatch(setProjectValue({ lateralGroup: integratedGroup }));
      await updateLaterals(integratedGroup);
      return;
    }

    if (!projectData.integrated && onlineGroup && groups.data.rootResults.find((item) => item.groups === onlineGroup)) {
      dispatch(setProjectValue({ lateralGroup: onlineGroup }));
      await updateLaterals(onlineGroup);
      return;
    }

    dispatch(setProjectValue({ lateralGroup: groups.data.rootResults[0].groups }));

    await updateLaterals(groups.data.rootResults[0].groups);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    const currentMasterGroupChosen = masterGroups.find((mg) => mg.id === projectData.lateralMasterGroup);
    const isDripTapeTypeChosen = currentMasterGroupChosen?.mastergroupname.includes('Drip Tape');

    dispatch(
      setProjectValue({
        lateralGroup: e.target.value as string,
        headlossCalc: isDripTapeTypeChosen ? 'D.W-RE' : 'D.W',
      }),
    );

    if (projectData.integrated) {
      dispatch(setLateralStateValue({ integratedGroup: e.target.value as string }));
    }
    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineGroup: e.target.value as string }));
    }

    resetFilters();

    await updateLaterals(e.target.value as string);
  };

  const onLateralChange = (_: any, item: CatalogItem) => {
    if (projectData.integrated) {
      dispatch(setLateralStateValue({ integratedCatalog: item.catlog }));
    }

    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineCatalog: item.catlog }));
    }

    dispatch(
      setProjectValue({
        lateralCatalog: item.catlog,
        ...(projectData.integrated && {
          lateralInletDia: Math_round(
            ConvertUnit(item.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
            3,
          ),
          emitterSpacing: Math_round(
            ConvertUnit(item.spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
            3,
          ),
          zoneEmitterSpacing: Math_round(
            ConvertUnit(item.spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
            3,
          ),
          emitterPMax: Math_round(ConvertUnit(item.pMax, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterPMin: Math_round(ConvertUnit(item.pMin, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterA: item.emitterQa,
          emitterB: item.emitterQb,
          lateralKd: item.kd,
          cvv: item.mManuFactCv,
          hwCoef: item.hwCof,
          emitterNominalPressure: item.qnom,
          emitterNominalFlow: item.PressureNominal_m_,
        }),
        ...(!projectData.integrated && {
          lateralInletDia: Math_round(
            ConvertUnit(item.intrnl, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
            3,
          ),
        }),
      }),
    );
  };
  const onDiameterChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({ lateralDiameter: e.target.value === ALL ? e.target.value : +(e.target.value as string) }),
    );
  };
  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setLateralStateValue({ lateralClass: e.target.value as string }));
  };
  const onFlowPerUnitChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({ lateralFlowPer: e.target.value === ALL ? e.target.value : +(e.target.value as string) }),
    );
  };
  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({
        lateralNominalFlow: e.target.value === ALL ? e.target.value : +(e.target.value as string),
      }),
    );
  };
  const onSpacingChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({ lateralSpacing: e.target.value === ALL ? e.target.value : +(e.target.value as string) }),
    );
  };

  const diameterFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.altInlet !== null) {
      return lateralDiameter !== ALL ? item.altInlet === lateralDiameter : true;
    }
    return lateralDiameter !== ALL ? item.inlet === lateralDiameter : true;
  };
  const classTypeFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.altClass !== null) {
      return lateralClass !== ALL ? item.altClass?.toString().trim() === lateralClass : true;
    }

    return lateralClass !== ALL ? item.class?.toString().trim() === lateralClass : true;
  };
  const flowPerUnitFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.Q_gpm_100ft_ !== null) {
      return lateralFlowPer !== ALL ? item.Q_gpm_100ft_ === lateralFlowPer : true;
    }

    return lateralFlowPer !== ALL ? item.Q_l_hr_100m_ === lateralFlowPer : true;
  };
  const nominalFlowFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.altQnom !== null) {
      return lateralNominalFlow !== ALL ? item.altQnom === lateralNominalFlow : true;
    }
    return lateralNominalFlow !== ALL ? item.qnom === lateralNominalFlow : true;
  };
  const spacingFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.altSpacing !== null) {
      return lateralSpacing !== ALL ? item.altSpacing === lateralSpacing : true;
    }
    return lateralSpacing !== ALL ? item.spacing === lateralSpacing : true;
  };

  const resetFilters = () => {
    dispatch(
      setLateralStateValue({
        lateralDiameter: ALL,
        lateralClass: ALL,
        lateralFlowPer: ALL,
        lateralNominalFlow: ALL,
        lateralSpacing: ALL,
      }),
    );
  };

  const lateralsFiltered = useMemo(() => {
    return laterals.filter(
      (item) =>
        diameterFilter(item) &&
        classTypeFilter(item) &&
        flowPerUnitFilter(item) &&
        nominalFlowFilter(item) &&
        spacingFilter(item),
    );
  }, [lateralDiameter, lateralClass, lateralNominalFlow, lateralSpacing, lateralFlowPer, laterals]);

  useEffect(() => {
    if (lateralsFiltered.length && !lateralsFiltered.find((item) => item.catlog === projectData.lateralCatalog)) {
      onLateralChange(null, lateralsFiltered[0]);
    }
  }, [lateralsFiltered]);

  const diameters = useMemo(
    () => [
      ALL,
      ...new Set(
        laterals
          .map((item) =>
            projectData.region === 'USA' && item.altInlet !== null ? (item.altInlet ?? '') : (item.inlet ?? ''),
          )
          .sort((a, b) => a - b),
      ),
    ],
    [laterals],
  );

  const classTypes = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item))
          .map((item) =>
            projectData.region === 'USA' && item.altClass !== null
              ? item.altClass?.toString().trim()
              : item.class?.toString().trim(),
          )
          .sort((a, b) => Number(a) - Number(b)),
      ),
    ];
  }, [lateralDiameter, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.catlog === projectData.lateralCatalog)) {
      dispatch(
        setLateralStateValue({
          lateralClass: ALL,
          lateralFlowPer: ALL,
          lateralNominalFlow: ALL,
          lateralSpacing: ALL,
        }),
      );
    }
  }, [classTypes]);

  const flowPerUnits = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item) && classTypeFilter(item))
          .map((item) =>
            projectData.region === 'USA' && item.Q_gpm_100ft_ !== null ? (item.Q_gpm_100ft_ ?? 0) : item.Q_l_hr_100m_,
          )
          .sort((a, b) => a - b),
      ),
    ];
  }, [lateralDiameter, lateralClass, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.catlog === projectData.lateralCatalog)) {
      dispatch(
        setLateralStateValue({
          lateralFlowPer: ALL,
          lateralNominalFlow: ALL,
          lateralSpacing: ALL,
        }),
      );
    }
  }, [flowPerUnits]);

  const nominalFlows = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item) && classTypeFilter(item) && flowPerUnitFilter(item))
          .map((item) => (projectData.region === 'USA' && item.altQnom !== null ? (item.altQnom ?? 0) : item.qnom))
          .sort((a, b) => a - b),
      ),
    ];
  }, [lateralDiameter, lateralClass, lateralFlowPer, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.catlog === projectData.lateralCatalog)) {
      dispatch(
        setLateralStateValue({
          lateralNominalFlow: ALL,
          lateralSpacing: ALL,
        }),
      );
    }
  }, [nominalFlows]);

  const spacings = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter(
            (item) =>
              diameterFilter(item) && classTypeFilter(item) && nominalFlowFilter(item) && flowPerUnitFilter(item),
          )
          .map((item) =>
            projectData.region === 'USA' && item.altQnom !== null ? (item.altSpacing ?? 0) : item.spacing,
          )
          .sort((a, b) => a - b),
      ),
    ];
  }, [lateralDiameter, lateralClass, lateralNominalFlow, lateralFlowPer, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.catlog === projectData.lateralCatalog)) {
      dispatch(
        setLateralStateValue({
          lateralSpacing: ALL,
        }),
      );
    }
  }, [spacings]);

  const lateralSubtype = useMemo(
    () => (lateralGroups.find((item) => item.groups === projectData.lateralGroup) ? projectData.lateralGroup : ''),
    [projectData.lateralGroup, lateralGroups],
  );

  const lateralProduct = useMemo(
    () => lateralsFiltered.find((item) => item.catlog === projectData.lateralCatalog) ?? lateralsFiltered[0],
    [projectData.lateralCatalog, lateralsFiltered],
  );

  const lateralClassValue = useMemo(
    () => classTypes.find((item) => item === lateralClass) ?? '',
    [lateralClass, classTypes],
  );

  const lateralFlowPerValue = useMemo(
    () => flowPerUnits.find((item) => item === lateralFlowPer) ?? '',
    [lateralFlowPer, flowPerUnits],
  );

  const lateralNominalFlowValue = useMemo(
    () => nominalFlows.find((item) => item === lateralNominalFlow) ?? '',
    [lateralNominalFlow, nominalFlows],
  );

  const lateralSpacingValue = useMemo(
    () => spacings.find((item) => item === lateralSpacing) ?? '',
    [lateralSpacing, spacings],
  );

  const filteredMasterGroups = useMemo(
    () =>
      masterGroups
        .filter((item) => item.lateral)
        .filter((item) =>
          projectData.integrated ? item.mastergroupname.includes('Drip') : !item.mastergroupname.includes('Drip'),
        ),
    [masterGroups, projectData.integrated],
  );

  const getLateralMasterGroup = () => {
    if (projectData.integrated && integratedMasterGroup) {
      return integratedMasterGroup;
    }

    if (!projectData.integrated && onlineMasterGroup) {
      return onlineMasterGroup;
    }

    return (
      filteredMasterGroups.find((item) => item.id === projectData.lateralMasterGroup)?.id ?? filteredMasterGroups[0]?.id
    );
  };

  const lateralMasterGroup = getLateralMasterGroup();

  useEffect(() => {
    if (!filteredMasterGroups.find((item) => item.id === projectData.lateralMasterGroup))
      onMasterGroupChange(lateralMasterGroup);
  }, [lateralMasterGroup]);

  return {
    masterGroups: filteredMasterGroups,
    masterGroupId: lateralMasterGroup,
    onMasterGroupChange,
    lateralSubtype,
    onGroupTypeChange,
    diameter: lateralDiameter,
    onDiameterChange,
    classType: lateralClassValue,
    onClassTypeChange,
    flowPerUnit: lateralFlowPerValue,
    onFlowPerUnitChange,
    nominalFlow: lateralNominalFlowValue,
    onNominalFlowChange,
    spacing: lateralSpacingValue,
    onSpacingChange,
    diameters,
    classTypes,
    flowPerUnits,
    nominalFlows,
    spacings,
    lateralProduct,
    lateralsFiltered,
    onLateralChange,
  };
};
