import { FC, PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  getComparisonEmittersEDCThunk,
  getComparisonEmittersFamiliesThunk,
  getEmitterGroupsThunk,
  getEmittersEDCThunk,
  getEmittersFamiliesThunk,
  getEmittersThunk,
  getLateralGroupsThunk,
  getLateralsThunk,
  getMainlineGroupsThunk,
  getMainlinesThunk,
  getMasterGroupsThunk,
  getProjectDataThunk,
  getProjectsThunk,
  getSubmainGroupsThunk,
  getSubmainsThunk,
  getUnitsThunk,
  modalActions,
  setProjectValue,
} from 'shared/slices';
import { getConvertedProjectValuesFromBE } from '../../shared/lib';

export const PrefetchData: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { username } = useAppSelector((state) => state.auth);
  const { currentProject, loading: isProjectsLoading, projects } = useAppSelector((state) => state.projects);
  const {
    emitterFamilies,
    comparisonEmitterFamilies,
    loading: isEmitterFamiliesLoading,
    comparisonLoading: isComparisonEmitterFamiliesLoading,
  } = useAppSelector((state) => state.emitterFamilies);
  const { loading: isMasterGroupsLoading } = useAppSelector((state) => state.masterGroups);
  const { projectData, loading: isProjectDataLoading } = useAppSelector((state) => state.projectData);
  const { units, loading: isUnitsLoading } = useAppSelector((state) => state.units);

  // useEffect(() => {
  //   if (isProjectsError) {
  //     showError('SOMETHING WENT WRONG');
  //   }
  // }, [isProjectsError]);

  useEffect(() => {
    if (!username) return;

    const userId = localStorage.getItem('ht-user-id');
    const userToken = localStorage.getItem('token');

    if (!userId || !userToken) return;

    Promise.all([
      dispatch(getProjectsThunk()),
      dispatch(getMasterGroupsThunk()),
      dispatch(getEmittersFamiliesThunk()),
      dispatch(getComparisonEmittersFamiliesThunk()),
    ]).catch((error) => {
      console.error('Error occurred in thunks:', error);
    });
  }, [username]);

  useEffect(() => {
    if (!username) return;

    if (!projects.length && !isProjectsLoading) {
      dispatch(modalActions.toggleNewProject());
      return;
    }
  }, [projects, isProjectsLoading]);

  useEffect(() => {
    const isLoading =
      isProjectsLoading ||
      isProjectDataLoading ||
      isMasterGroupsLoading ||
      isMasterGroupsLoading ||
      isComparisonEmitterFamiliesLoading;

    if (isLoading) {
      return;
    }

    dispatch(getUnitsThunk());
  }, [
    isProjectsLoading,
    isProjectDataLoading,
    isMasterGroupsLoading,
    isMasterGroupsLoading,
    isComparisonEmitterFamiliesLoading,
  ]);

  useEffect(() => {
    if (isProjectsLoading || isMasterGroupsLoading || !projects.length) return;

    const userId = localStorage.getItem('ht-user-id');

    if (!userId) return;

    dispatch(getProjectDataThunk({ projectId: currentProject.projectID }));
  }, [isProjectsLoading, isMasterGroupsLoading, projects]);

  useEffect(() => {
    if (isEmitterFamiliesLoading) return;

    dispatch(getEmittersEDCThunk({ ename: emitterFamilies[0]?.groupId }));
  }, [isEmitterFamiliesLoading]);

  useEffect(() => {
    if (isComparisonEmitterFamiliesLoading) return;

    dispatch(getComparisonEmittersEDCThunk({ ename: comparisonEmitterFamilies[1]?.groupId }));
  }, [isComparisonEmitterFamiliesLoading]);

  useEffect(() => {
    if (isProjectDataLoading || isUnitsLoading) return;

    dispatch(setProjectValue(getConvertedProjectValuesFromBE({ projectData, destUnits: units })));
  }, [isProjectDataLoading, isUnitsLoading]);

  useEffect(() => {
    if (isProjectDataLoading || isUnitsLoading) return;

    const { lateralGroup, emitterGroup, submainGroup, mainlineGroup, region } = projectData;

    Promise.all([
      dispatch(
        getLateralGroupsThunk({
          masterGroup: projectData.lateralMasterGroup,
          region,

        }),
      ),
      dispatch(
        getEmitterGroupsThunk({
          masterGroup: projectData.emitterMasterGroup,
          region,

        }),
      ),
      dispatch(
        getMainlineGroupsThunk({
          masterGroup: projectData.mainlineMasterGroup,
          region,
        }),
      ),
      dispatch(
        getSubmainGroupsThunk({
          masterGroup: projectData.submainMasterGroup,
          region,
        }),
      ),
      dispatch(
        getLateralsThunk({
          group: lateralGroup,
          region,
        }),
      ),
      dispatch(
        getEmittersThunk({
          group: emitterGroup,
          region,
        }),
      ),
      dispatch(
        getSubmainsThunk({
          group: submainGroup,
          region,
        }),
      ),
      dispatch(
        getMainlinesThunk({
          group: mainlineGroup,
          region,
        }),
      ),
    ]).catch((error) => {
      console.error('Error occurred in thunks:', error);
    });
  }, [isProjectDataLoading, isUnitsLoading]);

  return <>{children}</>;
};
