import { useEffect, useMemo } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { CatalogItem, CatalogsResponse } from 'shared/models';
import { SelectChangeEvent } from '@mui/material';
import {
  getEmitterGroupsThunk,
  getEmittersThunk,
  setEmitters,
  setLateralStateValue,
  setProjectValue,
} from 'shared/slices';
import { METRIC_DEFAULTS } from 'shared/constants';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';

const ALL = 'All';

export const useEmitterFilters = () => {
  const dispatch = useAppDispatch();
  const { masterGroups } = useAppSelector((state) => state.masterGroups);
  const { projectData } = useAppSelector((st) => st.projectData);
  const {
    lateralValues: { emitterNominalFlow, emitterSpacing },
  } = useAppSelector((st) => st.lateralState);
  const { units } = useAppSelector((st) => st.units);
  const { emitters, emitterGroups } = useAppSelector((st) => st.emitters);

  const updateEmitters = async (group: string) => {
    let emitters: CatalogsResponse | undefined;

    try {
      emitters = await dispatch(
        getEmittersThunk({
          group,
          region: projectData.region,
        }),
      ).unwrap();
    } catch (e) {
      dispatch(setEmitters([]));
      console.error(e);
    }

    if (!emitters) return;

    dispatch(
      setProjectValue({
        emitterCatalog: emitters.data[0]?.catlog,
        ...(!projectData.integrated && {
          // EmitterSpacing: Math_round(
          //   ConvertUnit(
          //     emitters.GetCatalogsResult.RootResults[0].Spacing,
          //     METRIC_DEFAULTS.EmitterSpacing,
          //     units.emitterSpacing,
          //     null
          //   ),
          //   3
          // ),
          zoneEmitterSpacing: Math_round(
            ConvertUnit(emitters.data[0]?.spacing, METRIC_DEFAULTS.EmitterSpacing, units.emitterSpacing, null),
            3,
          ),
          zoneEmitterFlowRate: Math_round(
            ConvertUnit(emitters.data[0]?.qnom ?? 0, METRIC_DEFAULTS.Flow, units.flow, null),
            3,
          ),
          emitterPMax: Math_round(
            ConvertUnit(emitters.data[0]?.pMax, METRIC_DEFAULTS.Pressure, units.pressure, null),
            3,
          ),
          emitterPMin: Math_round(
            ConvertUnit(emitters.data[0]?.pMin, METRIC_DEFAULTS.Pressure, units.pressure, null),
            3,
          ),
          emitterA: emitters.data[0]?.emitterQa,
          emitterB: emitters.data[0]?.emitterQb,
          lateralKd: emitters.data[0]?.kd,
          cvv: emitters.data[0]?.mManuFactCv,
          hwCoef: emitters.data[0]?.hwCof,
          emitterNominalPressure: emitters.data[0]?.qnom,
          emitterNominalFlow: emitters.data[0]?.PressureNominal_m_,
        }),
      }),
    );
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ emitterMasterGroup: e.target.value as string }));
    resetFilters();

    const groups = await dispatch(getEmitterGroupsThunk({
      masterGroup: e.target.value as string,
      region: projectData.region,
    })).unwrap();

    if (!groups) return;

    dispatch(setProjectValue({ emitterGroup: groups.data.rootResults[0].groups }));

    await updateEmitters(groups.data.rootResults[0].groups);
  };

  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ emitterGroup: e.target.value as string }));
    resetFilters();

    await updateEmitters(e.target.value as string);
  };
  const onEmitterChange = (_: any, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        emitterCatalog: item.catlog,
        ...(!projectData.integrated && {
          // EmitterSpacing: Math_round(
          //   ConvertUnit(item.Spacing, METRIC_DEFAULTS.EmitterSpacing, units.emitterSpacing, null),
          //   3
          // ),
          zoneEmitterSpacing: Math_round(
            ConvertUnit(item.spacing, METRIC_DEFAULTS.EmitterSpacing, units.emitterSpacing, null),
            3,
          ),
          emitterPMax: Math_round(ConvertUnit(item.pMax, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterPMin: Math_round(ConvertUnit(item.pMin, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterA: item.emitterQa,
          emitterB: item.emitterQb,
          lateralKd: item.kd,
          cvv: item.mManuFactCv,
          hwCoef: item.hwCof,
          emitterNominalPressure: item.qnom,
          emitterNominalFlow: item.PressureNominal_m_,
        }),
      }),
    );
  };

  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({
        emitterNominalFlow: e.target.value === ALL ? e.target.value : +(e.target.value as string),
      }),
    );
  };
  const onSpacingChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({ emitterSpacing: e.target.value === ALL ? e.target.value : +(e.target.value as string) }),
    );
  };

  const nominalFlowFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.altQnom !== null) {
      return emitterNominalFlow !== ALL ? item.altQnom === emitterNominalFlow : true;
    }

    return emitterNominalFlow !== ALL ? item.qnom === emitterNominalFlow : true;
  };
  const spacingFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.altSpacing !== null) {
      return emitterSpacing !== ALL ? item.altSpacing === emitterSpacing : true;
    }
    emitterSpacing;
    return emitterSpacing !== ALL ? item.spacing === emitterSpacing : true;
  };

  const resetFilters = () => {
    dispatch(setLateralStateValue({ emitterNominalFlow: ALL, emitterSpacing: ALL }));
  };

  const emittersFiltered = useMemo(() => {
    return emitters.filter((item) => nominalFlowFilter(item) && spacingFilter(item));
  }, [emitterNominalFlow, emitterSpacing, emitters]);

  useEffect(() => {
    if (emittersFiltered.length && !emittersFiltered.find((item) => item.catlog === projectData.emitterCatalog)) {
      onEmitterChange(null, emittersFiltered[0]);
    }
  }, [emittersFiltered]);

  const nominalFlows = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emitters
          .map((item) => (projectData.region === 'USA' && item.altQnom !== null ? item.altQnom : item.qnom))
          .sort((a, b) => a - b),
      ),
    ];
  }, [emitters]);

  useEffect(() => {
    if (!emittersFiltered.find((item) => item.catlog === projectData.emitterCatalog)) {
      dispatch(
        setLateralStateValue({
          emitterNominalFlow: ALL,
          emitterSpacing: ALL,
        }),
      );
    }
  }, [nominalFlows]);

  const spacings = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emitters
          .filter((item) => nominalFlowFilter(item))
          .map((item) => (projectData.region === 'USA' && item.altSpacing !== null ? item.altSpacing : item.spacing))
          .sort((a, b) => a - b),
      ),
    ];
  }, [emitterNominalFlow, emitters]);

  useEffect(() => {
    if (!emittersFiltered.find((item) => item.catlog === projectData.emitterCatalog)) {
      dispatch(
        setLateralStateValue({
          emitterSpacing: ALL,
        }),
      );
    }
  }, [spacings]);

  const emitterSubtype = useMemo(
    () =>
      emitterGroups.find((item) => item.groups === projectData.emitterGroup?.trim())
        ? projectData.emitterGroup?.trim()
        : '',
    [projectData.emitterGroup, emitterGroups],
  );
  const emitterProduct = useMemo(
    () => emittersFiltered.find((item) => item.catlog === projectData.emitterCatalog) ?? emittersFiltered[0],
    [projectData.emitterCatalog, emittersFiltered],
  );

  const emitterNominalFlowValue = useMemo(
    () => nominalFlows.find((item) => item === emitterNominalFlow) ?? '',
    [emitterNominalFlow, nominalFlows],
  );

  const emitterSpacingValue = useMemo(
    () => spacings.find((item) => item === emitterSpacing) ?? '',
    [emitterSpacing, spacings],
  );

  const filteredMasterGroups = useMemo(
    () => masterGroups.filter((item) => item.emitter),
    [masterGroups, projectData.integrated],
  );

  return {
    masterGroups: filteredMasterGroups,
    masterGroupId: projectData.emitterMasterGroup,
    onMasterGroupChange,
    emitterSubtype,
    onGroupTypeChange,
    nominalFlow: emitterNominalFlowValue,
    onNominalFlowChange,
    spacing: emitterSpacingValue,
    onSpacingChange,
    nominalFlows,
    spacings,
    emitterProduct,
    emittersFiltered,
    onEmitterChange,
  };
};
