import { Box } from '@mui/material';
import { setProjectValue, toggleTemplate } from 'shared/slices';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Modal, RadioSwitcher } from 'shared/ui';
import { EmitterBlock, GeneralBlock, LateralBlock, MainlineBlock, SubmainBlock } from './components';
import { CatalogItem } from 'shared/models';
import { useTranslation } from 'react-i18next';

export const Template = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { openTemplate } = useAppSelector((st) => st.modals);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { emitters } = useAppSelector((st) => st.emitters);
  const { laterals } = useAppSelector((st) => st.laterals);

  const integratedValues = [t('integrated'), t('onLine')];

  const toggle = () => dispatch(toggleTemplate());

  const handleToggleIntegrated = () => {
    dispatch(setProjectValue({ integrated: !projectData.integrated }));
    handleSetCurrentProductData(!projectData.integrated);
  };

  const handleSetCurrentProductData = (isIntegrated: boolean) => {
    const currentLateral = laterals.find((item) => item.catlog === projectData.lateralCatalog) as CatalogItem;
    const currentEmitter = emitters.find((item) => item.catlog === projectData.emitterCatalog) as CatalogItem;

    if (isIntegrated) {
      dispatch(
        setProjectValue({
          lateralInletDia: currentLateral.intrnl,
          emitterSpacing: currentLateral.spacing,
          emitterPMax: currentLateral.pMax,
          emitterPMin: currentLateral.pMin,
          emitterA: currentLateral.emitterQa,
          emitterB: currentLateral.emitterQb,
          lateralKd: currentLateral.kd,
          cvv: currentLateral.mManuFactCv,
          hwCoef: currentLateral.hwCof,
        })
      );
    } else {
      dispatch(
        setProjectValue({
          lateralInletDia: currentLateral.intrnl,
          emitterSpacing: currentEmitter.spacing,
          emitterPMax: currentEmitter.pMax,
          emitterPMin: currentEmitter.pMin,
          emitterA: currentEmitter.emitterQa,
          emitterB: currentEmitter.emitterQb,
          lateralKd: currentEmitter.kd,
          cvv: currentEmitter.mManuFactCv,
          hwCoef: currentEmitter.hwCof,
        })
      );
    }
  };

  return (
    <Modal maxWidth="md" title={t('projectTemplate')} open={openTemplate} onClose={toggle}>
      <Box>
        <GeneralBlock />
        <Box mb={1} mt={2}>
          <RadioSwitcher
            values={integratedValues}
            trueFlag={projectData.integrated}
            onChange={handleToggleIntegrated}
          />
        </Box>
        {!projectData.integrated && <EmitterBlock />}
        <LateralBlock />
        <SubmainBlock />
        <MainlineBlock />
      </Box>
    </Modal>
  );
};
